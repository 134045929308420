<template>
  <div
    v-if="
      data.type !== 'recommendation' ||
      (data.type === 'recommendation' && isAuthed)
    "
    class="relative z-0 delay-500 hover:z-50 hover:delay-300"
  >
    <SectionsItemSliderRecommendation
      v-if="data.type === 'recommendation'"
      v-bind="data"
    />
    <SectionsItemSliderInner v-else v-bind="data" />
  </div>
</template>

<script lang="ts" setup>
import type { ItemSliderProps } from '@autobid/ui/types/components/ItemSlider'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'

defineProps<{ data: ItemSliderProps }>()

const { isAuthed } = useAutobidAuth()
</script>
