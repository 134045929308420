<template>
  <ItemSliderInner v-if="data?.length" v-bind="props" :ids="data" />
</template>

<script lang="ts" setup>
import type { ItemSliderProps } from '@autobid/ui/types/components/ItemSlider'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import { useQuery } from '@tanstack/vue-query'
import { onServerPrefetch } from 'vue'
import { SLIDER_RECOMMENDATION_KEY } from '@autobid/ui/constants/CAR_SLIDER_KEYS'
import ItemSliderInner from './ItemSliderInner.vue'

const props = defineProps<ItemSliderProps>()
const headers = useRequestHeaders(['cookie'])
const { $customFetch } = useCustomFetch()

const { data, suspense } = useQuery({
  queryKey: [SLIDER_RECOMMENDATION_KEY],
  queryFn: async () => {
    const params = new URLSearchParams({
      'per-page': props.itemsCount.toString(),
      'get-cars-ids': '1'
    })

    const resp = await $customFetch<{ carsIDs: string[] }>('/api/backend', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      body: {
        queryApi: 'webapi',
        queryUrl: `/v1/cars/recommendation?${params}`
      }
    })

    return resp?.carsIDs.map(Number) ?? []
  }
})

onServerPrefetch(suspense)
</script>
