<template>
  <swiper-slide>
    <common-link
      :href="barometerUrl"
      class="item-slider-thumbnail-wrapper relative block w-full overflow-hidden after:absolute after:inset-0 after:z-20 after:bg-primary after:opacity-0 after:transition hover:after:opacity-20"
      @click="$emit('moveToBarometer')"
      @mouseenter="$emit('showPreview', { images, car })"
      @mouseleave="$emit('hidePreview')"
    >
      <CommonPicture
        v-if="images[0]"
        :images="images[0].links"
        :sizes="GALLERY_IMAGE_SIZES"
        default-size="l"
        :alt="images[0].description.categoryLabel"
        :loading="index < 3 ? 'eager' : 'lazy'"
        class="relative z-10 aspect-[4/3] w-full object-cover"
      />
      <ElementsCarThumbnailPlaceholder v-else />
    </common-link>

    <div class="block bg-primary p-3.5 text-onPrimary">
      <div class="mb-2.5 flex h-12 justify-between">
        <CommonLink
          underline
          :href="barometerUrl"
          class="h-max overflow-hidden text-base font-bold after:!bg-white"
          :class="{
            'mr-2': isAuthed
          }"
          :title="car.name"
          @click="$emit('moveToBarometer')"
        >
          <span class="line-clamp-2">{{ car.name }}</span>
        </CommonLink>

        <span
          v-if="isAuthed"
          :class="{
            'relative after:pointer-events-none after:absolute after:inset-0 after:z-10 after:rounded-md after:border-2 after:border-white after:duration-200 hover:after:-inset-0.5':
              !car.hasBid && car.isWatcher
          }"
        >
          <DynamicAuctionItemListCarActionsBidded
            v-if="car.hasBid"
            class="!h-12 !w-12"
          />
          <ElementsCarWatch
            v-else
            class="h-12 w-12 rounded-md"
            :class="{
              '!bg-transparent text-white': car.isWatcher,
              'bg-white !text-primary': !car.isWatcher
            }"
            :car-id="car.id"
            :watched="car.isWatcher"
            @update="handleUpdateWatch"
          />
        </span>
      </div>

      <div class="flex flex-col gap-1 pt-2">
        <div class="flex items-center justify-between gap-1 text-xl font-bold">
          <p>{{ $t('shared-car.bid') }}:</p>
          <p v-if="isAuthed">{{ formatPrice(car.price.current) }}</p>
          <p v-else>{{ $t('shared-car.confidential') }}</p>
        </div>
        <div class="flex items-center justify-between gap-1 text-base">
          <p>{{ $t('shared-car.start-price') }}:</p>
          <p>{{ formatPrice(car.price.start) }}</p>
        </div>
      </div>
      <p class="ml-auto mt-1 line-clamp-1 w-max text-base">
        {{ car.taxInformation ?? '&nbsp;' }}
      </p>
    </div>
  </swiper-slide>
</template>

<script lang="ts" setup>
import type { AuctionCar } from '@autobid/ui/types/Car'
import { computed } from 'vue'
import { useAuctionPagesLink } from '@autobid/ui/composables/useAuctionPagesLink'
import { formatPrice } from '@autobid/ui/utils/formatPrice'
import { useUpdateWatch } from '@autobid/ui/composables/car/useUpdateWatch'
import type { ItemSliderProps } from '@autobid/ui/types/components/ItemSlider'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'

interface Props {
  car: AuctionCar
  index: number
  type: ItemSliderProps['type']
  recommendationReferer?: ItemSliderProps['recommendationReferer']
}

const emits = defineEmits([
  'showPreview',
  'hidePreview',
  'moveToBarometer',
  'observe'
])

const props = defineProps<Props>()
const { isAuthed } = useAutobidAuth()
const GALLERY_IMAGE_SIZES = {
  l: 768,
  m: 424
}
const images = props.car.imageGroups.itemMainImageSubgroup
const { getLink } = useAuctionPagesLink(props.car.appId)
const barometerUrl = computed(() =>
  getLink({
    page: 'barometer',
    slug: props.car.slug
  })
)

const { update } = useUpdateWatch()
const handleUpdateWatch = (value: boolean) => {
  update(props.car.id, value)

  if (value) {
    emits('observe')
  }
}
</script>

<style lang="scss">
.item-slider-thumbnail-wrapper {
  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  img {
    transition: 0.3s;
    transform: scale(1.02);
  }
}
</style>
