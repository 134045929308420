<template>
  <div>
    <p
      v-if="title"
      class="mb-3 px-3 text-2xl font-bold uppercase text-primary xl:px-0"
    >
      {{ title }}
    </p>

    <div
      v-if="!isSliderInitialized || isFetching"
      class="grid grid-cols-3 gap-3"
    >
      <div
        v-for="i in 3"
        :key="i"
        class="col-span-1 h-[540px] animate-pulse bg-gray-300"
      ></div>
    </div>

    <swiper-container
      v-show="isSliderInitialized && !isFetching"
      :key="type"
      ref="sliderRef"
      class="relative z-10"
      init="false"
      :navigation="true"
      v-bind="
        autoplay
          ? {
              'auto-play': true,
              'autoplay-delay': (playDuration ?? 4) * 1000,
              'autoplay-disable-on-interaction': false,
              'autoplay-pause-on-mouse-enter': true
            }
          : undefined
      "
      space-between="15"
    >
      <Slide
        v-for="(car, index) in data?.data?.items?.items ?? []"
        :key="`${type}-${car.id}`"
        :car="car"
        :index="index"
        :type="type"
        :recommendation-referer="recommendationReferer"
        @show-preview="showPreview"
        @hide-preview="hidePreview"
        @move-to-barometer="
          countConversion(car.id, props.recommendationReferer)
        "
        @observe="
          countConversion(car.id, `${props.recommendationReferer}%2Bobserve`)
        "
      />
    </swiper-container>

    <CarHoverDetails
      ref="carHoverDetails"
      :car="hoverData.car"
      :images="hoverData.images"
      centered
      @move-to-barometer="
        countConversion(hoverData.car.id, props.recommendationReferer)
      "
      @move-to-details="
        countConversion(hoverData.car.id, props.recommendationReferer)
      "
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { register } from 'swiper/element/bundle'
import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { useWebSocketStore } from '@autobid/nuxt-pinia-store/store/useWebSocketStore'
import type { ItemSliderProps } from '@autobid/ui/types/components/ItemSlider'
import type { AuctionCarsVariables } from '@autobid/ui/composables/useFetchCars'
import { useFetchCars } from '@autobid/ui/composables/useFetchCars'
import {
  ITEM_SLIDER_KEY,
  SLIDER_RECOMMENDATION_KEY
} from '@autobid/ui/constants/CAR_SLIDER_KEYS'
import type { CarFinishedEvent } from '@autobid/ui/composables/car/useCarSockets/events/useCarFinished/useCarFinished'
import CarHoverDetails from '@autobid/ui/components/elements/car/CarHoverDetails.vue'
import type { AuctionCar, CarImage } from '@autobid/ui/types/Car'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import Slide from './Slide.vue'

register(true)

const props = defineProps<ItemSliderProps & { ids?: number[] }>()

const queryClient = useQueryClient()
const sliderRef = ref()
const isSliderInitialized = ref(false)
const carHoverDetails = ref()
const hoverData = ref({
  car: {} as AuctionCar,
  images: [] as CarImage[]
})
const { locale } = useI18n()
const { fetchCars } = useFetchCars()
const { startListen } = useWebSocketStore()
const resumeAutoplayDebounce = ref()
const { $customFetch } = useCustomFetch()

const { data, isFetching } = useQuery({
  queryKey: [ITEM_SLIDER_KEY, props.type],
  queryFn: async () => {
    const filters: AuctionCarsVariables = {
      pageNumber: 0,
      pageSize: props.itemsCount,
      lang: locale.value,
      stages: ['BEFORE_AUCTION', 'IN_AUCTION'],
      publicationStatus: ['PUBLISHED'],
      rankingListType: 'HIGHLIGHT',
      ids: props.ids ?? [],
      bidParams: { limit: 0 },
      sortBy: [
        {
          name: 'auctionStartDate',
          order: 'ASCENDING'
        }
      ]
    }

    if (props.type === 'highlight') {
      filters.highlighted = true
    }

    const omits = {
      counters: true,
      noFilterMetadata: true,
      suppliers: true
    }

    const resp = await fetchCars(filters, omits)

    if (props.ids?.length) {
      resp.data.items.items = props.ids
        .map((propCarId) =>
          resp.data.items.items.find((car) => car.id === propCarId)
        )
        .filter(Boolean)
    }

    return resp
  }
})
const amountOfFinishedCars = computed(
  () =>
    data.value?.data.items?.items.filter((el) => el.stage === 'FINISHED').length
)

const initSlider = () => {
  const swiperEl = sliderRef.value

  if (!swiperEl || typeof window === 'undefined') {
    return
  }

  const params = {
    slidesPerView: 3,
    slidesPerGroup: props.rowSlide ? 3 : 1,
    breakpoints: {
      320: {
        slidesPerView: 1,
        ...(props.rowSlide ? { slidesPerGroup: 1 } : {})
      },
      641: {
        slidesPerView: 2,
        ...(props.rowSlide ? { slidesPerGroup: 2 } : {})
      },
      1024: {
        slidesPerView: 3,
        ...(props.rowSlide ? { slidesPerGroup: 3 } : {})
      },
      1280: {
        slidesPerView: 4,
        ...(props.rowSlide ? { slidesPerGroup: 4 } : {})
      }
    },
    injectStyles: [
      `
      .swiper-button-next,
      .swiper-button-prev{
        color: white;
        font-weight: 800;
        text-shadow: 0px 3px 6px #000000;
        filter: drop-shadow(0 1px 1px #58585a);
      }

      .swiper-button-next::after,
      .swiper-button-prev::after{
        font-size: 3.25rem;
      }

      .swiper-button-prev.swiper-button-disabled,
      .swiper-button-next.swiper-button-disabled{
        pointer-events: auto;
      } 
      `
    ]
  }

  Object.assign(swiperEl, params)

  swiperEl.initialize()
  isSliderInitialized.value = true
}

const invalidateData = (event: CarFinishedEvent) => {
  if (event.input === 'premium' || !data.value?.data?.items.items.length) return

  let wasItemOfSliderUpdated = false

  queryClient.setQueryData([ITEM_SLIDER_KEY, props.type], () => {
    return {
      ...data.value,
      data: {
        items: {
          items: data.value.data.items.items.map((el) => {
            if (el.id === event.context.carId) {
              wasItemOfSliderUpdated = true

              return {
                ...el,
                stage: 'FINISHED'
              }
            }

            return el
          })
        }
      }
    }
  })

  if (!wasItemOfSliderUpdated) return

  const maxAmountOfFinishedCars = data.value.data.items.items.length - 2

  if (amountOfFinishedCars.value >= maxAmountOfFinishedCars) {
    queryClient.invalidateQueries([SLIDER_RECOMMENDATION_KEY])
    queryClient.invalidateQueries([ITEM_SLIDER_KEY])
  }
}

const showPreview = (data: { images: CarImage[]; car: AuctionCar }) => {
  if (props.autoplay) {
    clearTimeout(resumeAutoplayDebounce.value)
    sliderRef.value.swiper.autoplay.stop()
  }

  hoverData.value = data
  carHoverDetails.value?.showPreview(data.images[0]?.links.l)
}

const hidePreview = () => {
  if (props.autoplay) {
    resumeAutoplayDebounce.value = setTimeout(() => {
      if (carHoverDetails.value?.previewImgSrc?.length) return

      sliderRef.value.swiper.autoplay.start()
    }, 500)
  }

  carHoverDetails.value?.hidePreview()
}

type ConversionSuffix = 'observe'

const countConversion = (
  carId: number,
  recommendationReferer:
    | ItemSliderProps['recommendationReferer']
    | `${ItemSliderProps['recommendationReferer']}%2B${ConversionSuffix}`
) => {
  if (props.type !== 'recommendation' || !props.recommendationReferer) return

  try {
    $customFetch('/api/backend', {
      method: 'POST',
      body: {
        queryApi: 'webapi',
        queryMethod: 'POST',
        queryUrl: `/v1/recommendation/followed/${carId}/${recommendationReferer}`
      }
    })
  } catch {}
}

onMounted(() => {
  startListen(
    'premium.CAR_FINISHED',
    `item-slider-${props.type}`,
    invalidateData
  )
})

onBeforeUnmount(() => {
  clearTimeout(resumeAutoplayDebounce.value)
})

watch(sliderRef, () => {
  initSlider()
})
</script>
